.comp {
  margin: 20px 0;
  position: absolute;
  top: 85px;
  margin-left: -50px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.comp-form {
  padding: 7px 10px;
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-style: 16px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-bottom: 10px;
  background-color: rgba(128, 128, 128, 0.3);
}
.comp-whats:hover {
  background-color: #01ca66;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.comp-whats:hover:after,
.comp-whats:hover:before {
  opacity: 1;
  left: 50px;
}
.comp-whats:after {
  content: "compartilhar no whats";
  background-color: #01ca66;
  position: absolute;
  left: 300px;
  border: solid 3px #01ca66;
  border-radius: 6px;
  padding: 5px 12px;
  white-space: nowrap;
  font-size: 13px;
  color: #ffffff;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  letter-spacing: 0.03em;
}
.comp-whats:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  color: #01ca66;
  border-width: 5px 10px 6px 0;
  position: absolute;
  margin-left: -3px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  pointer-events: none;
  left: 300px;
  top: 50%;
  opacity: 0;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.comp-face:hover:after,
.comp-face:hover:before {
  opacity: 1;
  left: 50px;
}
.comp-face:after {
  content: "compartilhar no face";
  background-color: #4267b2;
  position: absolute;
  left: 300px;
  border: solid 3px #4267b2;
  border-radius: 6px;
  padding: 5px 12px;
  white-space: nowrap;
  font-size: 13px;
  color: #ffffff;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  letter-spacing: 0.03em;
}
.comp-face:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  color: #4267b2;
  border-width: 5px 10px 6px 0;
  position: absolute;
  margin-left: -3px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  pointer-events: none;
  left: 300px;
  top: 50%;
  opacity: 0;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.comp-face:hover {
  background-color: #4267b2;
}
.comp-link:hover:after,
.comp-link:hover:before {
  opacity: 1;
  left: 50px;
}
.comp-link:after {
  content: "compartilhar no linkedin";
  background-color: #0a66c2;
  position: absolute;
  left: 300px;
  border: solid 3px #0a66c2;
  border-radius: 6px;
  padding: 5px 12px;
  white-space: nowrap;
  font-size: 13px;
  color: #ffffff;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  letter-spacing: 0.03em;
}
.comp-link:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  color: #0a66c2;
  border-width: 5px 10px 6px 0;
  position: absolute;
  margin-left: -3px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  pointer-events: none;
  left: 300px;
  top: 50%;
  opacity: 0;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.comp-link:hover {
  background-color: #0a66c2;
}
.comp-copy:hover:after,
.comp-copy:hover:before {
  opacity: 1;
  left: 50px;
}
.comp-copy:after {
  content: "copiar link";
  background-color: var(--cinza);
  position: absolute;
  left: 300px;
  border: solid 3px var(--cinza);
  border-radius: 6px;
  padding: 5px 12px;
  white-space: nowrap;
  font-size: 13px;
  color: #ffffff;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  letter-spacing: 0.03em;
}
.comp-copy:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  color: var(--cinza);
  border-width: 5px 10px 6px 0;
  position: absolute;
  margin-left: -3px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  pointer-events: none;
  left: 300px;
  top: 50%;
  opacity: 0;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.comp-copy:hover {
  background-color: var(--cinza);
}

/* Artigo */
.art {
  min-height: 900px;
  padding: 100px 0;
  position: relative;
}
.art .container {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: var(--font);
}
.art span {
  font-size: 20px;
  color: var(--cinza);
}
.art h1 {
  font-size: 60px;
  font-weight: 900;
  color: var(--verde);
  line-height: 1.1;
  margin-bottom: 40px;
}
.art p {
  margin: 10px 0;
  color: black;
  font-weight: 300;
  font-size: 22px;
  line-height: 40px;
  letter-spacing: 0.01em;
  white-space: pre-wrap;
}
.art h2 {
  margin: 30px 0 5px;
  font-size: 28px;
  font-weight: 900;
  color: var(--cinza);
}
.art h3 {
  margin: 5px 0;
  font-size: 24px;
  font-weight: 700;
  color: var(--cinza);
}
@media only screen and (max-width: 768px) {
  .art {
    padding: 50px 15px;
  }
  .art span {
    font-size: 12px;
    font-weight: 500;
  }
  .art h1 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .art p {
    font-size: 18px;
  }
  .art h2 {
    font-size: 21px;
  }
  .art h3 {
    font-size: 18px;
  }
}
