/*-------------- Top Button --------------*/
.btn_top {
  position: fixed;
  background-color: transparent;
  border: none;
  bottom: 0px;
  right: 0px;
  margin: 30px;
  z-index: 100;
  cursor: pointer;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
}
.btn_top.ativo {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
@media only screen and (max-width: 768px) {
  .btn_top {
    margin: 5px;
  }
}