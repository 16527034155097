.confirm {
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  z-index: 500;
  position: fixed;
}

.al-container {
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px 20px;
  height: auto;
  min-height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--cinza);
  transition: all 0.3s ease;
  box-shadow: 0px 0px 50px var(--white);
}
.al-title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 70px;
  color: var(--white);
}
.al-title span {
  color: var(--dourado);
}
.al-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.al-buttons button {
  background-color: var(--cinza);
  border: 1px solid var(--dourado);
  color: var(--dourado);
  transition: all 0.3s ease;
  border-radius: 10px;
  cursor: pointer;
  font-size: 22px;
  padding: 20px 40px;
}
.cnf:hover,
.can:hover {
  background-color: var(--dourado);
  color: var(--cinza);
}
