.services-container {
  background-color: var(--white);
  min-height: 1000px;
  padding: 120px 0;
}

/* CALL SERVICE */
.call-container {
  margin-bottom: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cs-text {
  width: 50%;
}
.cs-title {
  position: relative;
}
.cs-title h1 {
  font-weight: 300;
  font-size: 45px;
  color: var(--cinza);
}
.cs-title h1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 130px;
  width: 250px;
  background-color: var(--dourado);
  height: 2px;
}

.cs-sub-title p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
}

.cs-button {
  background-color: var(--dourado);
  cursor: pointer;
  color: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
  margin-top: 40px;
  font-size: 18px;
  border: none;
}

@media (max-width: 768px) {
  .services-container {
    padding: 50px 0;
  }
  .container {
    padding: 0 15px;
  }
  .cs-text {
    width: 100%;
  }
  .cs-title h1::after {
    top: 100px;
  }
}

/* FAQ */
.faq .title {
  position: relative;
  margin-bottom: 80px;
}
.faq .title h1 {
  font-size: 45px;
  font-weight: 300;
  text-align: center;
  font-family: var(--font);
}

.faq .title::after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 150px;
  background-color: var(--dourado);
  height: 2px;
}

@media (max-width: 768px) {
  .faq .title h1 {
    font-size: 40px;
  }
  .cs-title h1 {
    font-size: 40px;
  }
}
/* ------ acordian styl -----*/
.acord-container {
  align-items: flex-start;
  display: flex;
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.acord-box {
  transition: 0.5s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
  width: 100%;
}
.acord-title {
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.acord-title h1 {
  font-size: 22px;
}
.acord-text {
  width: 100%;
  line-height: 30px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .acord-container {
    padding: 0 15px;
  }
  #fracao {
    width: 110%;
    margin-left: -16px;
  }
  #grafico {
    width: 110%;
    margin-left: -25px;
  }
  #grafico2 {
    width: 110%;
    margin-left: -25px;
  }
}

/* Work proce */
.wk-cont {
  display: flex;
  align-items: center;
  margin-bottom: 150px;
}
.wk-title h1 {
  font-weight: 300;
  position: relative;
  text-align: center;
  font-size: 45px;
  color: var(--cinza);
  margin-bottom: 100px;
}
.wk-title h1::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 60px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 250px;
  background-color: var(--dourado);
  height: 2px;
}
.wk-text {
  margin-left: 20px;
}
.wk-text h1 {
  font-weight: normal;
  margin-bottom: 10px;
}
.wk-text p {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .question-img {
    display: none;
  }
  .wk-title h1::after {
    top: 150px;
  }
  .wk-img {
    display: none;
  }
  .wk-title h1 {
    font-size: 40px;
  }
}
