/* NavBar style */
.floating_nav_bar {
  background-color: transparent;
  z-index: 100;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
  top: 0px;
  position: fixed;
}
.floating_nav_bar.ativo {
  background-color: var(--cinza);
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
}
.header {
  background-color: transparent;
  height: 90px;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.filler {
  height: 100px;
  position: relative;
}
.floating_nav_bar_on {
  background-color: var(--cinza);
  z-index: 100;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
  top: 0px;
  position: fixed;
}
.floating_nav_bar_on.ativo {
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
}
.img-on {
  height: 50px !important;
}
.logo {
  cursor: pointer;
}
.logo img {
  height: 0px;
}
.img1.ativo {
  height: 50px !important;
}
.img2.ativo {
  height: 50px !important;
}
.logo-text {
  margin-left: 5px;
  text-align: center;
}
.logo-text h1 {
  font-family: "Times New Roman", Times, serif;
  color: rgb(248, 248, 248);
  margin: 0px;
  font-weight: normal;
  line-height: 45px;
  font-size: 34px;
}
.logo-text h1.ativo {
  color: var(--verde);
}
.logo-text p {
  color: rgb(248, 248, 248);
  font-size: 8px;
  margin-left: 12px;
  font-family: "Times New Roman", Times, serif;
  letter-spacing: 13px;
}
.logo-text p.ativo {
  color: var(--dourado);
}
.logo-text-on {
  margin-left: 5px;
  text-align: center;
}
.logo-text-on h1 {
  color: var(--verde);
  font-family: "Times New Roman", Times, serif;
  margin: 0px;
  font-weight: normal;
  line-height: 45px;
  font-size: 34px;
}
.logo-text-on p {
  font-size: 8px;
  margin-left: 12px;
  font-family: "Times New Roman", Times, serif;
  letter-spacing: 13px;
  color: var(--dourado);
}
@media only screen and (max-width: 768px) {
  .header {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: transparent;
    position: fixed;
    z-index: 200;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    padding-top: 20px;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    height: 90px;
  }
  .logo {
    margin-left: 15px;
  }
  .logo-text h1 {
    line-height: 35px;
    font-size: 24px;
  }
  .logo-text p {
    letter-spacing: 7px;
    margin-left: 10px;
  }
  .filler {
    display: none;
  }
  .logo-text p {
    color: var(--dourado);
  }
  .logo-text h1 {
    color: var(--verde);
  }
  .logo img {
    height: 50px;
  }
  .img1 {
    height: 0px !important;
  }
  .img1.ativo {
    height: 0px !important;
  }
}
.menu_list button {
  background-color: transparent;
  border: none;
}
.menu_list ul li {
  font-size: 18px;
  cursor: pointer;
  position: relative;
  font-weight: 300;
  color: rgb(248, 248, 248);
  margin: 0px 10px;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.menu_list ul li:hover {
  color: var(--dourado);
}
.menu_list ul li::after {
  content: "";
  bottom: 0px;
  position: absolute;
  width: 0px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  left: 0px;
  height: 1px;
  background-color: var(--dourado);
}
.menu_list ul li:hover::after {
  width: 100%;
  opacity: 1;
}
.btn-click {
  padding: 8px 20px;
  margin-bottom: 5px;
  margin-left: 10px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  color: rgb(248, 248, 248);
  font-weight: 500;
  font-family: var(--font);
  font-size: 18px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: var(--dourado) !important;
}
.btn-click:hover {
  background-color: #00da3a !important;
}
.menu-icon-toggle {
  display: none;
}
.menu {
  visibility: hidden;
  pointer-events: none;
}
.menu-navigation {
  display: none;
}
@media only screen and (max-width: 768px) {
  .header.ativo {
    -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
    background-color: var(--cinza);
  }
  .menu-navigation {
    display: block;
  }
  .menu_list {
    display: none;
  }
  .menu-icon-toggle {
    display: block;
    border: none;
    background-color: transparent;
    width: 30px;
    margin-top: 10px;
    margin-right: 30px;
    height: 30px;
    position: relative;
    z-index: 9;
  }
  .home .menu-icon-toggle {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .menu-icon-toggle span {
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    background-color: var(--dourado);
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    -webkit-transition: opacity 0.3s ease 0.3s;
    -o-transition: opacity 0.3s ease 0.3s;
    transition: opacity 0.3s ease 0.3s;
  }
  .home:not(.open) .menu-icon-toggle span {
    background-color: var(--dourado);
  }
  .menu-icon-toggle::before,
  .menu-icon-toggle::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: var(--dourado);
    -webkit-transition: top 0.3s ease 0.3s, bottom 0.3s ease 0.3s,
      -webkit-transform 0.3s ease 0s;
    transition: top 0.3s ease 0.3s, bottom 0.3s ease 0.3s,
      -webkit-transform 0.3s ease 0s;
    -o-transition: transform 0.3s ease 0s, top 0.3s ease 0.3s,
      bottom 0.3s ease 0.3s;
    transition: transform 0.3s ease 0s, top 0.3s ease 0.3s,
      bottom 0.3s ease 0.3s;
    transition: transform 0.3s ease 0s, top 0.3s ease 0.3s,
      bottom 0.3s ease 0.3s, -webkit-transform 0.3s ease 0s;
  }
  .menu-icon-toggle::before {
    top: 6px;
  }
  .menu-icon-toggle::after {
    bottom: 6px;
  }
  .open .menu-icon-toggle span {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
  }
  .open .menu-icon-toggle::before,
  .open .menu-icon-toggle::after {
    background-color: var(--dourado);
  }
  .open .menu-icon-toggle::before {
    top: calc(50% - 1px);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .open .menu-icon-toggle::after {
    bottom: calc(50% - 1px);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .open .menu-icon-toggle::before,
  .open .menu-icon-toggle::after {
    -webkit-transition: top 0.3s ease 0s, bottom 0.3s ease 0s,
      -webkit-transform 0.3s ease 0.3s;
    transition: top 0.3s ease 0s, bottom 0.3s ease 0s,
      -webkit-transform 0.3s ease 0.3s;
    -o-transition: top 0.3s ease 0s, bottom 0.3s ease 0s,
      transform 0.3s ease 0.3s;
    transition: top 0.3s ease 0s, bottom 0.3s ease 0s, transform 0.3s ease 0.3s;
    transition: top 0.3s ease 0s, bottom 0.3s ease 0s, transform 0.3s ease 0.3s,
      -webkit-transform 0.3s ease 0.3s;
  }
  .menu-background {
    visibility: hidden;
    width: 300%;
    height: 400px;
    position: absolute;
    left: -130%;
    background-color: rgb(248, 248, 248);
    -webkit-transition: background-position 0.5s ease 0s,
      visibility 0.5s ease 1s, -webkit-transform 0.5s ease 0.5s;
    transition: background-position 0.5s ease 0s, visibility 0.5s ease 1s,
      -webkit-transform 0.5s ease 0.5s;
    -o-transition: background-position 0.5s ease 0s, transform 0.5s ease 0.5s,
      visibility 0.5s ease 1s;
    transition: background-position 0.5s ease 0s, transform 0.5s ease 0.5s,
      visibility 0.5s ease 1s;
    transition: background-position 0.5s ease 0s, transform 0.5s ease 0.5s,
      visibility 0.5s ease 1s, -webkit-transform 0.5s ease 0.5s;
  }
  .open .menu-background {
    visibility: visible;
    -webkit-transition: background-position 0.5s ease 0s,
      -webkit-transform 0.5s ease 0.5s;
    transition: background-position 0.5s ease 0s,
      -webkit-transform 0.5s ease 0.5s;
    -o-transition: background-position 0.5s ease 0s, transform 0.5s ease 0.5s;
    transition: background-position 0.5s ease 0s, transform 0.5s ease 0.5s;
    transition: background-position 0.5s ease 0s, transform 0.5s ease 0.5s,
      -webkit-transform 0.5s ease 0.5s;
  }
  .menu-background.top {
    -webkit-transform: rotate(-45deg) translateY(-150%);
    -ms-transform: rotate(-45deg) translateY(-150%);
    transform: rotate(-45deg) translateY(-150%);
    background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(50%, rgb(209, 171, 106)),
        color-stop(50%, rgb(37, 45, 47))
      ) -100% 100% / 100% 200%;
    background: -o-linear-gradient(
        bottom,
        rgb(209, 171, 106) 50%,
        rgb(37, 45, 47) 50%
      ) -100% 100% / 100% 200%;
    background: linear-gradient(
        to top,
        rgb(209, 171, 106) 50%,
        rgb(37, 45, 47) 50%
      ) -100% 100% / 100% 200%;
  }
  .open .menu-background.top {
    -webkit-transform: rotate(-45deg) translateY(-49%);
    -ms-transform: rotate(-45deg) translateY(-49%);
    transform: rotate(-45deg) translateY(-49%);
    background-position: 0px 0px;
  }
  .menu-background.middle {
    -webkit-transform: rotate(-45deg) translateY(50%) scaleY(0);
    -ms-transform: rotate(-45deg) translateY(50%) scaleY(0);
    transform: rotate(-45deg) translateY(50%) scaleY(0);
    background: rgb(37, 45, 47);
  }
  .open .menu-background.middle {
    -webkit-transform: rotate(-45deg) translateY(50%) scaleY(1);
    -ms-transform: rotate(-45deg) translateY(50%) scaleY(1);
    transform: rotate(-45deg) translateY(50%) scaleY(1);
  }
  .menu-background.bottom {
    -webkit-transform: rotate(-45deg) translateY(250%);
    -ms-transform: rotate(-45deg) translateY(250%);
    transform: rotate(-45deg) translateY(250%);
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(50%, rgb(37, 45, 47)),
        color-stop(50%, rgb(209, 171, 106))
      )
      0px -100% / 100% 200%;
    background: -o-linear-gradient(rgb(37, 45, 47) 50%, rgb(209, 171, 106) 50%)
      0px -100% / 100% 200%;
    background: linear-gradient(rgb(37, 45, 47) 50%, rgb(209, 171, 106) 50%) 0px -100% /
      100% 200%;
  }
  .open .menu-background.bottom {
    -webkit-transform: rotate(-45deg) translateY(149%);
    -ms-transform: rotate(-45deg) translateY(149%);
    transform: rotate(-45deg) translateY(149%);
    background-position: 0px 0px;
  }
  .menu {
    position: absolute;
    pointer-events: all;
    top: 50vh;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    visibility: hidden;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    z-index: 99999;
  }
  .open .menu {
    visibility: visible;
  }
  .menu li {
    opacity: 0;
    margin-bottom: 25px;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  .menu li button,
  .menu li a {
    background-color: transparent;
    border: none;
    position: relative;
    font-size: 30px;
    font-weight: 300;
    text-decoration: none;
    color: rgb(248, 248, 248);
  }
  .open .menu li {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .open .menu li:nth-child(1) {
    -webkit-transition-delay: 1.1s;
    -o-transition-delay: 1.1s;
    transition-delay: 1.1s;
  }
  .open .menu li:nth-child(2) {
    -webkit-transition-delay: 1.2s;
    -o-transition-delay: 1.2s;
    transition-delay: 1.2s;
  }
  .open .menu li:nth-child(3) {
    -webkit-transition-delay: 1.3s;
    -o-transition-delay: 1.3s;
    transition-delay: 1.3s;
  }
  .open .menu li:nth-child(4) {
    -webkit-transition-delay: 1.4s;
    -o-transition-delay: 1.4s;
    transition-delay: 1.4s;
  }
  .open .menu li:nth-child(5) {
    -webkit-transition-delay: 1.5s;
    -o-transition-delay: 1.5s;
    transition-delay: 1.5s;
  }
  .open .menu li:nth-child(6) {
    -webkit-transition-delay: 1.6s;
    -o-transition-delay: 1.6s;
    transition-delay: 1.6s;
  }
  .open .menu li:nth-child(7) {
    -webkit-transition-delay: 1.7s;
    -o-transition-delay: 1.7s;
    transition-delay: 1.7s;
  }
}
