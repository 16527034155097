body {
  background-color: var(--cinza);
}

.hole-map {
  width: 100%;
  background: url("../../img/map.png") center no-repeat;
  background-color: var(--cinza);
  height: 500px;
  margin-bottom: 100px;
}

.contato {
  position: relative;
  padding: 120px 0;
  height: 100%;
  background-color: transparent;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.padrao-title {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 50px;
  -ms-flex-item-align: start;
  align-self: flex-start;
  color: var(--cinza);
  position: relative;
}

.map-view {
  position: absolute;
  background-color: var(--cinza);
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
  font-family: var(--font);
  font-size: 18px;
  font-weight: 300;
  color: white;
  width: auto;
  white-space: nowrap;
  padding: 10px 40px;
  left: 0px;
  border-radius: 5px;
  bottom: 25px;
}
.map-view:hover {
  background-color: var(--dourado);
  color: var(--cinza);
}
.form {
  background-color: var(--white);
  position: absolute;
  right: 0;
  top: -100px;
  padding: 50px;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 450px;
}
.form-style {
  width: 100%;
  background-color: rgba(203, 255, 184, 0.082);
  border: none;
  border-bottom: 2px solid rgba(172, 172, 172, 0.4);
  font-size: 14px;
  color: var(--cinza);
  font-weight: 500;
  padding: 10px 20px 10px 0px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--white) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
}
.form-style::-webkit-input-placeholder {
  font-size: 14px;
  color: var(--cinzaClaro);
  font-weight: 500;
}
.form-style::-moz-placeholder {
  font-size: 14px;
  color: var(--cinzaClaro);
  font-weight: 500;
}
.form-style:-ms-input-placeholder {
  font-size: 14px;
  color: var(--cinzaClaro);
  font-weight: 500;
}
.form-style::-ms-input-placeholder {
  font-size: 14px;
  color: var(--cinzaClaro);
  font-weight: 500;
}
.form-style::placeholder {
  font-size: 14px;
  color: var(--cinzaClaro);
  font-weight: 500;
}
.form-box.error input,
.form-box.error textarea {
  border-bottom: 2px solid rgb(160, 0, 0);
}
.form-box.success input,
.form-box.success textarea {
  border-bottom: 2px solid var(--dourado);
}
.form-style:focus {
  border-bottom: 2px solid var(--dourado);
}
.form-box {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.form-box small {
  color: rgb(160, 0, 0);
  visibility: hidden;
}
.form-box.error small {
  color: rgb(160, 0, 0);
  visibility: visible;
}
.form-box.success small {
  visibility: hidden;
}
.form-box i {
  position: absolute;
  visibility: hidden;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  top: 35%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}
#last-box i {
  top: 80%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.form-box.success .fa-check-circle {
  color: var(--dourado);
  visibility: visible;
}
.form-box.error .fa-exclamation-circle {
  color: rgb(160, 0, 0);
  visibility: visible;
}
.form textarea {
  resize: none;
  width: 100%;
}

.button-box button {
  width: 100%;
  height: 60px;
  background-color: var(--cinza);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: none;
}

.button-box {
  width: 100%;
  height: 80px;
  position: relative;
}
.button {
  height: 80px;
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}

.progress-bar {
  position: absolute;
  height: 10px;
  width: 0;
  right: 0;
  top: 50%;
  left: 50%;
  border-radius: 5px;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  background-color: var(--dourado);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.svg-file {
  width: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  right: 0;
  pointer-events: none;
}
.check {
  fill: none;
  stroke: #ffffff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.map {
  background-color: grey;
  width: 100%;
  height: 400px;
}
@media only screen and (max-width: 768px) {
  .form {
    width: 90%;
    padding: 35px;
    top: 50px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  .hole-map {
    height: 850px;
  }
  .map-view {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.void-filler {
  background-color: var(--cinza);
  height: 100px;
  width: 100%;
}
