footer {
  background-color: var(--cinza);
  padding-top: 80px;
}
.bib-vers {
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}
.bib-vers small {
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: var(--white);
  font-weight: 500;
}
.bib-vers h2 {
  font-size: 32px;
  font-weight: 500;
  color: var(--dourado);
}
.bib-vers span {
  position: absolute;
  font-size: 60px;
  margin: 0 10px;
  color: var(--dourado);
}
.leftq {
  left: -35px;
  top: -20px;
}
.rightq {
  right: -25px;
  bottom: -5px;
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}
.contatos {
  width: 100%;
  display: -ms-grid;
  display: grid;
  margin-bottom: 50px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
.contatos-box {
  padding: 30px;
  color: rgb(248, 248, 248);
  text-align: center;
}
.contatos-box h1 {
  font-size: 16px;
  font-weight: 700;
  color: var(--dourado);
  text-align: center;
  margin-bottom: 15px;
}
.contatos-box p {
  font-weight: 300;
  font-size: 14px;
  margin-top: 5px;
  color: var(--cinzaClaro);
}
.telefone {
  background-color: rgb(43, 51, 53);
}
.email {
  background-color: rgb(40, 48, 50);
}
.endereco {
  background-color: rgb(43, 51, 53);
}
@media only screen and (max-width: 768px) {
  .contatos {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .bib-vers {
    padding: 0 30px;
  }
  .bib-vers h2 {
    font-size: 24px;
  }
  .leftq {
    left: 0;
  }
  .rightq {
    right: 0;
    bottom: 40px;
  }
}
.top-footer {
  width: 100%;
  display: -ms-grid;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
}
.top-footer .about {
  background-color: transparent;
  padding: 0px 15px 0px 0px;
  color: white;
}
.top-footer .about h1 {
  color: var(--dourado);
  margin-bottom: 30px;
  font-size: 18px;
  text-transform: uppercase;
}
.top-footer .about p {
  font-size: 14px;
  font-weight: 500;
}
.top-footer .about a {
  color: white;
}
.top-footer .about a:hover {
  color: var(--dourado);
}
.funcionamento {
  margin-top: 20px;
  color: var(--cinzaClaro);
  font-weight: 500;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between !important;
}
.horarios {
  text-align: right;
}
.services {
  padding-right: 50px;
}
.services h1 {
  color: var(--dourado);
  margin-bottom: 30px;
  font-size: 18px;
  text-transform: uppercase;
}
.serv {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.serv ul li {
  margin-bottom: 9px;
}
.serv a {
  color: white;
}
.serv a:hover {
  color: var(--dourado);
}
.newsletter h1 {
  color: var(--dourado);
  margin-bottom: 30px;
  font-size: 18px;
  text-transform: uppercase;
}
.newsletter p {
  font-size: 14px;
  font-weight: 300;
  color: white;
  margin-bottom: 10px;
}
.input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  position: relative;
}
.input-group input {
  width: 100%;
  color: white;
  padding: 10px 15px;
  background-color: transparent;
  -webkit-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  -o-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  border: 2px solid rgb(43, 51, 53) !important;
}
#newsletter:-webkit-autofill,
#newsletter:-webkit-autofill:hover,
#newsletter:-webkit-autofill:focus,
#newsletter:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px var(--cinza) inset !important;
  -webkit-box-shadow: 0 0 0 1000px var(--cinza) inset !important;
  -webkit-text-fill-color: var(--white) !important;
}

.input-group input:hover,
.input-group input:focus {
  border: 2px solid var(--dourado) !important;
}
#newsletter:hover + #sbt-news,
#newsletter:focus + #sbt-news {
  background-color: var(--dourado) !important;
}
.input-group input::-webkit-input-placeholder {
  font-family: var(--font);
  font-size: 16px;
  color: var(--cinzaClaro);
}
.input-group input::-moz-placeholder {
  font-family: var(--font);
  font-size: 16px;
  color: var(--cinzaClaro);
}
.input-group input:-ms-input-placeholder {
  font-family: var(--font);
  font-size: 16px;
  color: var(--cinzaClaro);
}
.input-group input::-ms-input-placeholder {
  font-family: var(--font);
  font-size: 16px;
  color: var(--cinzaClaro);
}
.input-group input::placeholder {
  font-family: var(--font);
  font-size: 16px;
  color: var(--cinzaClaro);
}
.input-group button {
  cursor: pointer;
  -webkit-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  -o-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  background-color: rgb(43, 51, 53);
  border: none;
}
.fa-arrow {
  font-size: 20px;
  position: absolute;
  right: 50%;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  color: var(--white);
}
.fa-check {
  opacity: 0;
}
.sbt-news.submited {
  pointer-events: none;
}
.sbt-input.submited {
  pointer-events: none;
}
@media only screen and (max-width: 768px) {
  .top-footer {
    padding: 0 15px;
  }
  .top-footer h1 {
    margin: 20px 0;
  }
  .top-footer .about {
    padding: 0;
  }
  .services {
    padding: 0;
  }
  .funcionamento {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    margin-bottom: 20px;
  }
  .newsletter {
    margin-bottom: 50px;
  }
}

.bottom-footer {
  color: var(--cinzaClaro);
  border-top: 1px solid rgba(130, 133, 144, 0.2);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between !important;
  height: 80px;
}
.bottom-footer p {
  font-weight: 300;
  font-size: 14px;
  margin-top: 5px;
  color: var(--cinzaClaro);
}
.bottom-footer a {
  color: var(--dourado);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  margin-left: 5px;
}
.bottom-footer a:hover {
  color: rgb(248, 248, 248);
}
.social-media a {
  text-align: center;
  margin: 0px 5px;
  background-color: rgb(43, 51, 53);
  border-radius: 5px;
  padding: 10px 15px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  color: rgb(248, 248, 248);
}
.social-media a:hover {
  color: var(--dourado);
}
@media only screen and (max-width: 768px) {
  .bottom-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    height: 150px;
  }
  .bottom-footer p {
    margin-bottom: 15px;
  }
  .social-media {
    margin: 10px 0;
  }
}
