.login-container {
  height: 100vh;
  width: 100%;
  background-color: var(--white);
  display: grid;
  place-items: center;
}
.lg-box {
  display: flex;
  padding: 50px 20px;
  flex-direction: column;
  align-items: center;
  height: 600px;
  width: 450px;
  border-radius: 15px;
  background-color: var(--white);
  box-shadow: 0 0 15px 2px rgb(0 0 0 / 10%);
}
.lg-title {
  font-family: var(--font);
  margin-top: 25px;
  color: var(--cinza);
}
.lg-img {
  margin-bottom: 50px;
}
.lg-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.lg-inpt {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  margin-bottom: 15px;
  width: 65%;
}
.lg-inpt:required:valid {
  border: 1px solid var(--dourado);
}

.lg-inpt::placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-family: var(--font);
  font-weight: 700;
}
.lg-button {
  background-color: var(--dourado);
  cursor: pointer;
  color: var(--white);
  font-family: var(--font);
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  border: none;
  width: 65%;
}
.lg-button:hover {
  background-color: var(--cinza);
  color: var(--white);
  font-family: var(--font);
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  width: 65%;
}
