/*-------------- Global Style --------------*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
:root {
  --verde: #3f7975;
  --dourado: #d1ab6a;
  --white: #f8f8f8;
  --azul: #8ccdb0;
  --cinza: #252d2f;
  --cinzaClaro: #828590;
  --font: "Roboto", sans-serif;
}
* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  text-decoration: none;
  font-family: var(--font);
}
body {
  width: 100%;
  text-decoration: none;
}
.container {
  max-width: 1200px;
  margin: 0px auto;
}
.normal-title {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 50px;
}
.flex_center_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex_center_column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    min-width: 100%;
  }
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: var(--cinza);
}
::-webkit-scrollbar-thumb {
  background: var(--dourado);
  height: 25%;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--verde);
}
/*-------------- Loading Screen --------------*/
#loading-screen {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgb(245, 245, 245);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.loading-logo {
  width: 200px;
  height: 285px;
}
.loading-bar {
  width: 200px;
  height: 2px;
  background: rgb(207, 207, 207);
  margin-top: 22px;
  position: relative;
  overflow: hidden;
}
.loading-bar::before {
  content: "";
  width: 120px;
  height: 3px;
  background: var(--verde);
  position: absolute;
  left: -34px;
  -webkit-animation: 1.5s ease 0s infinite normal none running bluebar;
  animation: 1.5s ease 0s infinite normal none running bluebar;
}
@-webkit-keyframes bluebar {
  50% {
    left: 180px;
  }
}
@keyframes bluebar {
  50% {
    left: 180px;
  }
}

/*-------------- Resumo Section --------------*/
.resumo button {
  border: none;
}
.resumo {
  background-color: rgb(248, 248, 248);
  width: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.tab {
  padding: 80px;
  color: rgb(248, 248, 248);
  cursor: pointer;
  position: relative;
}
.tab h1 {
  font-weight: 500;
  font-size: 24px;
  margin: 15px 0px;
}
.tab p {
  font-weight: 100;
  font-size: 16px;
}
.tab {
  overflow: hidden;
  z-index: 0;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.tab:before,
.tab:after {
  content: "";
  position: absolute;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  z-index: -1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--cinza);
}
.tab:before {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.tab:after {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.tab:hover:before {
  -webkit-transform: translateY(-49%);
  -ms-transform: translateY(-49%);
  transform: translateY(-49%);
}
.tab:hover:after {
  -webkit-transform: translateY(49%);
  -ms-transform: translateY(49%);
  transform: translateY(49%);
}

.tab .btn {
  margin-top: 10px;
  background-color: var(--dourado);
}
.tab .btn:hover {
  background-color: rgb(248, 248, 248);
  color: var(--cinza);
}
.t1 {
  background-color: rgb(61, 82, 88);
}
.t2 {
  background-color: rgb(55, 72, 77);
}
.t3 {
  background-color: rgb(46, 58, 61);
}
.t4 {
  background-color: rgb(37, 45, 47);
}
.servicos button {
  border: none;
}
.servicos {
  width: 100%;
  background-color: rgb(248, 248, 248);
  font-family: var(--font);
  padding: 120px 0px;
}
.servicos .container {
  min-width: 1200px;
}
.servicos-title h1 {
  font-weight: 300;
  text-align: center;
  font-size: 48px;
}
.servicos-title p {
  margin-top: 20px;
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
  text-align: center;
}
.servicos-banners {
  margin-top: 50px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 50px;
  text-align: center;
}
.bn {
  padding: 50px;
  background-color: white;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 5px;
  height: 308px;
  -webkit-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease;
}
.bn:hover {
  border: 1px solid var(--verde);
  transform: scale(1.1);
}
.bn h1 {
  font-size: 20px;
  color: var(--cinza);
  margin-top: 20px;
  font-weight: 400;
}
.bn p {
  margin-top: 10px;
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
  text-align: center;
  color: var(--cinzaClaro);
}
@media only screen and (max-width: 768px) {
  .tab {
    padding: 50px;
  }
  .servicos {
    padding: 50px 0px;
  }
  .servicos .container {
    min-width: 350px;
  }
  .servicos-title {
    padding: 0px 15px;
  }
  .servicos-title h1 {
    font-size: 40px;
  }
  .servicos-banners {
    padding: 0px 15px;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% - 30px), 1fr));
    gap: 20px;
  }
}

/*-------------- About Section --------------*/
.about {
  padding: 100px 0px;
  background-color: rgb(245, 245, 245);
}
.about button {
  border: none;
}
.about-img {
  background: url("img/faxada.png") -50px 0px / cover no-repeat;
  border-radius: 15px;
  width: 50%;
  height: 500px;
}
.about-text {
  padding: 0px 50px;
  width: 50%;
}
.about-text h1 {
  font-weight: 300;
  font-size: 48px;
}
.about-text p {
  margin: 15px 0px;
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 16px;
}
.about-text button {
  background-color: var(--dourado);
  margin-top: 70px;
}
.about-text button:hover {
  background-color: var(--cinza);
}
@media only screen and (max-width: 768px) {
  .about {
    padding: 50px 0px;
  }
  .about .flex_center_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .about-img {
    width: 95%;
    margin: 20px 15px;
  }
  .about-text {
    width: 95%;
    padding: 0px 15px;
  }
  .about-text button {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

/*-------------- Uteis Sectio --------------*/
.uteis {
  background-color: var(--cinza);
  height: 700px;
}

/* -------------- News Section --------------*/
.HomeBlog {
  background-color: rgb(248, 248, 248);
  font-family: var(--font);
  padding: 100px 0px;
  width: 100%;
}
.HomeBlog-title h1 {
  font-weight: 300;
  font-size: 48px;
}
.HomeBlog-banners {
  margin-top: 50px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 35px;
}
.HomeBlog-box {
  cursor: pointer;
  text-align: start;
  border: none;
  background-color: var(--white);
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  padding: 20px;
  height: 350px;
  padding-bottom: 40px;
  -webkit-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
}
.HomeBlog-box:hover .HomeBlog-arrow {
  left: -20px;
}
.HomeBlog-arrow {
  position: absolute;
  background-color: var(--cinza);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 10px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  color: white;
  padding-right: 30px;
  justify-content: flex-end;
  border-radius: 50px;
  bottom: 20px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  left: -200px;
  width: 150px;
  height: 50px;
}
.HomeBlog-box span {
  color: var(--verde);
}
.HomeBlog-box h2 {
  font-weight: 700;
  font-size: 14px;
  width: fit-content;
  color: var(--dourado);
  border-radius: 5px;
}
.HomeBlog-box h1 {
  font-size: 22px;
  color: var(--cinza);
  margin-top: 10px;
  font-weight: 700;
}
.HomeBlog-box p {
  margin: 20px 0px;
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
  color: var(--cinzaClaro);
}
.HomeBlog-box a {
  color: var(--verde);
}
.btn-mr {
  margin-top: 50px;
  cursor: pointer;
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  -webkit-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  color: var(--cinza);
  background-color: white;
}
.btn-mr:hover {
  color: var(--verde);
  -webkit-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1) inset;
}
@media only screen and (max-width: 768px) {
  .HomeBlog {
    padding: 50px 0;
  }
  .HomeBlog-title h1 {
    font-size: 40px;
  }
  .HomeBlog-banners {
    padding: 0px 15px;
  }
  .HomeBlog-box {
    height: auto;
  }
}

/*-------------- Our Clientes --------------*/
.our-cli {
  padding: 100px 0;
}
.cli-title {
  margin-bottom: 100px;
  text-align: center;
}
.cli-title h1 {
  font-size: 48px;
  color: var(--cinza);
  font-family: var(--font);
  font-weight: 300;
  position: relative;
}
.cli-title p {
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 16px;
}
.cli-grid {
  width: 100%;
  display: -ms-grid;
  display: grid;
  place-items: center;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}
.cli-grid .cli {
  -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: -ms-grid;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 768px) {
  .our-cli {
    padding: 50px 0;
  }
  .cli-title {
    margin-bottom: 20px;
  }
  .cli-title h1 {
    font-size: 40px;
  }
  .cli-title p {
    font-size: 14px;
    text-align: center;
    padding: 0 15px;
  }
  .cli-grid {
    padding: 0 15px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
}

/*-------------- Section direto --------------*/
.direto {
  width: 100%;
  height: 350px;
  background: url("img/call_photo.jpg") center no-repeat;
  -o-object-fit: cover;
  object-fit: cover;
}
.direto-text {
  text-align: center;
  height: 100%;
}
.direto-text h1 {
  font-size: 48px;
  margin-bottom: 50px;
  color: white;
  font-family: var(--font);
}
.direto-text a {
  padding: 15px 40px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  border-radius: 40px;
  background-color: var(--dourado);
  color: white;
  font-size: 18px;
}
.direto-text a:hover {
  background-color: var(--cinza);
}
@media only screen and (max-width: 768px) {
  .direto-text h1 {
    font-size: 40px;
    margin-bottom: 20px;
    padding: 0 20px;
  }
}
