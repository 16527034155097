/*-------------- Hero Section --------------*/
/* Background style */
.home {
  height: 100vh;
  width: 100%;
  position: relative;
}
.bc_img img {
  position: absolute;
  opacity: 0;
  z-index: -10;
  width: 100%;
  height: 100%;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -o-object-fit: cover;
  object-fit: cover;
}
.bc_img img.active {
  opacity: 1;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.overlay {
  position: absolute;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.64) 72.51%, rgba(0, 0, 0, 0.29) 104.68%);
  z-index: -9;
  opacity: 0.85;
  width: 100%;
  height: 100%;
}
/* background: linear-gradient(
180deg
, #000000 0%, rgba(255, 255, 255, 0) 150%); */
.slide {
  padding-top: 7%;
}
.baner {
  position: absolute;
  -webkit-transform: translate3d(0px, 100px, 0px);
  transform: translate3d(0px, 100px, 0px);
  opacity: 0;
}
.baner.active {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 1;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.baner.active button {
  display: block;
  margin-top: 10%;
}
.baner button {
  display: none;
}
.baner.active h1 {
  display: block;
}
.baner h1 {
  display: none;
}
.baner.active p {
  display: block;
}
.baner p {
  display: none;
}
.baner h1 {
  color: rgb(248, 248, 248);
  cursor: default;
  font-size: 85px;
  font-weight: 500;
}
.baner h2 {
  color: rgb(248, 248, 248);
  cursor: default;
  font-size: 85px;
  font-weight: 100;
}
.baner p {
  cursor: default;
  font-size: 32px;
  font-weight: 100;
  margin-top: 20px;
  color: rgb(248, 248, 248);
}
.btn {
  background-color: var(--dourado);
  margin-top: 15px;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  color: rgb(248, 248, 248);
  font-size: 28px;
  font-weight: 500;
  padding: 15px 40px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.baner .btn:hover {
  background-color: var(--cinza);
}
.navigation {
  z-index: 2;
  position: absolute;
  bottom: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dots {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  height: 15px;
  width: 15px;
  margin-right: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.dots.active {
  background-color: var(--white);
  border-radius: 10px;
  width: 40px;
}
@media only screen and (max-width: 768px) {
  .slide {
    padding-top: 55%;
    padding-left: 15px;
  }
  .baner h1 {
    font-size: 45px;
  }
  .baner h2 {
    font-size: 45px;
  }
  .baner p {
    font-size: 16px;
  }
  .navigation {
    bottom: 15vh;
  }
}
