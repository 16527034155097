.home-about {
  height: 100%;
}
.filler-about {
  background-color: var(--cinza);
  height: 90px;
}
.home-banner {
  background-color: var(--cinza);
  height: 28vh;
  /* margin-bottom: 20px; */
  /* border-radius: 0px 0 100px 100px; */
  /* box-shadow: 0px 15px 10px 0px rgb(0 0 0 / 15%); */
}
.home-banner .container {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  visibility: visible;
  position: relative;
  height: 100%;
  padding-bottom: 50px;
}
.home-banner h1 {
  font-size: 70px;
  font-family: var(--font);
  font-weight: 900;
  color: var(--dourado);
}
.home-banner button {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  border: none;
}
.home-banner .text a,
.home-banner .text p {
  font-size: 20px;
  color: white;
  margin-right: 10px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.home-banner .text a:hover {
  color: var(--dourado);
}
#nt-h1 {
  font-size: 60px;
  font-family: var(--font);
  font-weight: 500;
  color: var(--dourado);
}

@media only screen and (max-width: 768px) {
  .home-banner {
    height: 35vh;
    padding: 0 20px 0px;
    box-shadow: none;

    background-color: var(--cinza);
    margin-bottom: 10px;
    border-radius: 0px;
  }
  #nt-h1 {
    font-size: 28px;
    font-family: var(--font);
    font-weight: 500;
    color: var(--dourado);
  }
}
