.news {
  background-color: white;
  font-family: var(--font);
  width: 100%;
}

.news .container {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.nw-bn-container {
  margin-top: 0;
  align-items: flex-start !important;
  width: 100%;
}
.news-banners {
  margin-top: 0;
  -webkit-box-align: start;
  align-items: flex-start !important;
  width: 100%;
  margin: 10px 0;
  display: flex;
  min-height: 55vh;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 100px 0;
}

.news-box {
  border: none;
  width: 100%;
  text-align: start;
  margin: 0px 0px 100px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 5px 15px 2px rgb(0 0 0 / 15%);
  height: auto;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 20px;
  padding-bottom: 55px;
}
.news-box:hover .news-arrow {
  left: -20px;
}

.news-box h2 {
  font-weight: 700;
  font-size: 14px;
  width: fit-content;
  color: var(--dourado);
  border-radius: 5px;
}
.news-box h1 {
  font-size: 22px;
  color: var(--cinza);
  margin-top: 5px;
  font-weight: 700;
}
.news-box p {
  margin: 20px 0px;
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
  color: var(--cinzaClaro);
}

.news-arrow {
  position: absolute;
  background-color: var(--cinza);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-right: 30px;
  justify-content: flex-end;
  border-radius: 50px;
  font-weight: 500;
  bottom: 20px;
  transition: all 0.5s ease 0s;
  left: -200px;
  width: 150px;
  color: white;
  height: 50px;
}

.bp-conteudo p {
  overflow: hidden;
  height: 100px;
}

@media only screen and (max-width: 768px) {
  .news-box {
    margin: 50px 10px;
    box-shadow: 1px 5px 15px 2px rgb(0 0 0 / 20%);
  }
  .news-banners {
    padding: 10px;
  }
}
