
body {
  background-color: var(--white) !important;
}
/*-------------- Link section --------------*/
.links {
  margin: 120px 0;
}
.tribu-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 70px 0;
}
.tribu-links h1 {
  font-family: var(--font);
  color: var(--cinza);
  margin-bottom: 10px;
  margin-left: -5px;
  font-size: 38px;
  font-weight: 300;
}
.link-wrapper {
  width: 100%;
  display: -ms-grid;
  display: grid;
  padding-left: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.link-wrapper li {
  margin: 10px 0;
  color: var(--verde);
  font-size: 20px;
  list-style: circle;
}
.link-wrapper li:hover {
  color: var(--dourado);
}
.link-wrapper li a {
  color: var(--verde);
  font-weight: 300;
  font-size: 18px;
}
.link-wrapper li a:hover {
  color: var(--dourado);
}
@media only screen and (max-width: 768px) {
  .links {
    margin: 50px 0;
  }
  .tribu-links {
    margin: 25px 30px;
  }
}
