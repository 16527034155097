.historia {
  font-family: var(--font);
  margin: 120px 0;
}
.what-do {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 50px;
}
.what-do .text {
  width: 50%;
  padding-right: 50px;
}
.historia .text h1 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 50px;
}
.historia .text h3 {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 20px;
}
.historia .text p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
}
.what-do .about-img {
  width: 45%;
  height: 450px;
}
.story {
  margin-top: 100px;
}
.story h1 {
  font-size: 48px;
  padding-top: 60px;
  margin-bottom: 50px;
  font-weight: 300;
  position: relative;
}
.story h1::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0px;
  width: 2px;
  height: 1em;
  background: var(--dourado);
}

@media only screen and (max-width: 768px) {
  .historia {
    margin: 50px 0;
  }
  .what-do {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 25px;
  }
  .what-do .text {
    width: 100%;
    padding-right: 0px;
  }
  .what-do .text h1 {
    margin-bottom: 15px;
  }
  .what-do p {
    text-align: justify;
  }
  .what-do .about-img {
    display: none;
  }
  .story {
    margin-top: 50px;
  }
  .story h1 {
    margin: 0;
  }
}
