.m_bar {
  background-color: var(--white);
}
.bl-exit {
  padding: 10px 30px;
  font-size: 20px;
}

.bl-exit:hover {
  background-color: var(--cinza) !important;
}
.bl-container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bl-form {
  background-color: var(--white);
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 18px 30px -10px;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  border-radius: 10px;
  padding: 10px;
  margin-top: 90px;
  transition: 0.5s;
}
.bl-img {
  height: 20px;
  margin-left: 20px;
}
.bl-search {
  color: var(--cinza);
  background-color: var(--white);
  border-radius: 5px;
  font-size: 20px;
  border: none;
  width: 70%;
  padding: 10px 15px;
  width: 500px;
}
.bl-form:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 18px 50px -10px;
}
.bl-search::placeholder {
  color: #cacaca;
}
.bl-add {
  background-color: var(--verde);
  opacity: 0.3;
  transition: 0.5s ease;
  cursor: pointer;
  color: white;
  padding: 10px 15px;
  border-radius: 15px;
}
.bl-add:hover {
  opacity: 1;
}

.bl-data {
  margin: 10px 0;
  display: flex;
  min-height: 55vh;
  justify-content: center;
  flex-wrap: wrap;
}
.tb {
  overflow: hidden;
  border-bottom: 25px solid var(--white);
  width: 500px;
  height: 250px;
  word-break: break-all;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 18px 50px -10px;
  display: flex;
  border-radius: 10px;
  margin: 10px;
  justify-content: space-between;
  padding: 20px 35px 20px 20px;
}
.tb-price button {
  border: none;
  margin-right: 10px;
  margin-bottom: 15px;
  padding: 5px;
  width: 25px;
  cursor: pointer;
  border-radius: 5px;
}
.tb-price button:hover {
  background-color: var(--dourado);
}
.tb-title p {
  color: var(--dourado);
}

.pg-control {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font);
}
.pg-control span {
  font-size: 18px;
}
#btn-esq {
  visibility: hidden;
}
.btn-page {
  border: none;
  margin: 10px 25px;
  color: var(--cinza);
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  background-color: white;
  padding: 5px 20px;
  box-shadow: 1px 5px 15px 2px rgb(0 0 0 / 15%);
  border-radius: 10px;
}
.btn-page:hover {
  background-color: var(--cinza);
  color: white;
}
/*-----------------------Blog New -----------------------*/
.bn-container {
  width: 1200px;
  margin: 0 auto;
  margin-top: 150px;
}
.bn-form {
  font-family: var(--font);
}
.bn-input {
  display: flex;
  color: var(--cinza);
  font-weight: 500px;
  flex-direction: column;
}
.bn-input input,
.bn-input textarea {
  padding: 10px;
  resize: vertical;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: 0.2s ease;
  font-size: 18px;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 18px 50px -10px;
}
.bn-input input:focus,
.bn-input textarea:focus {
  border: 1px solid var(--dourado);
}

.bn-btn {
  border: none;
  width: 200px;
  cursor: pointer;
  transition: 0.2s ease;
  padding: 15px 35px;
  background-color: var(--dourado);
  border-radius: 10px;
  color: white;

  font-size: 16px;
  font-family: var(--font);
  margin-right: 20px;
  margin-bottom: 100px;
}
.bn-btn:hover {
  background-color: var(--cinza);
}

.img {
  height: 50px !important;
}
.l_text {
  margin-left: 5px;
  text-align: center;
}
.l_text h1 {
  font-family: "Times New Roman", Times, serif;
  color: var(--verde);
  margin: 0px;
  font-weight: normal;
  line-height: 45px;
  font-size: 34px;
}
.l_text p {
  color: var(--dourado);
  font-size: 8px;
  margin-left: 12px;
  font-family: "Times New Roman", Times, serif;
  letter-spacing: 13px;
}
